import React, { lazy } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from 'react-router-dom';

const RootLayout = lazy(() => import('../layout/RootLayout'));
const VerifyCode = lazy(() => import('../pages/Kiosk/VerifyCode'));
const ConformOrder = lazy(() => import('../pages/Kiosk/ConformOrder'));
const OpenBin = lazy(() => import('../pages/Kiosk/OpenBin'));
const Feedback = lazy(() => import('../pages/Kiosk/Feedback'));
const SecureBadgeLogin = lazy(() => import('../pages/Admin/SecureBadgeLogin'));
const AdminLogin = lazy(() => import('../pages/Admin/AdminLogin'));
const AdminPanel = lazy(() => import('../pages/Admin/AdminPanel'));
const AdminStock = lazy(() => import('../pages/Admin/AdminStock'));
const AdminVerify = lazy(() => import('../pages/Admin/AdminVerify'));
const AdminRemove = lazy(() => import('../pages/Admin/AdminRemove'));
const AdminSetting = lazy(() => import('../pages/Admin/AdminSetting'));
const AdminMaintenance = lazy(() => import('../pages/Admin/AdminMaintenance'));
const AdminForgotPassword = lazy(() => import('../pages/Admin/AdminForgotPassword'));

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route element={<RootLayout />}>
        <Route path="/" element={<VerifyCode />} />
        <Route path="/conformOrder" element={<ConformOrder />} />
        <Route path="/openBin" element={<OpenBin />} />
        <Route path="/feedback" element={<Feedback />} />
        <Route path="/securebadge" element={<SecureBadgeLogin />} />
        <Route path="/adminLogin" element={<AdminLogin />} />
        <Route path="/adminPanel" element={<AdminPanel />} />
        <Route path="/adminStock" element={<AdminStock />} />
        <Route path="/adminVerify" element={<AdminVerify />} />
        <Route path="/adminRemove" element={<AdminRemove />} />
        <Route path="/adminMaintenance" element={<AdminMaintenance />} />
        <Route path="/adminSetting" element={<AdminSetting />} />
        <Route path="/forgetPassword" element={<AdminForgotPassword />} />
      </Route>
    </Route>,
  ),
);

export default router;
