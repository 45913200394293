import React, { Suspense, useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { io } from 'socket.io-client';
import router from './router/router';
import './index.css';
import Loader from './components/fromComponents/Loader';
import sagaActions from './sagas/sagaActions';
import API from './common/axios';
import { updateData } from './redux/kioskSlice';
import { openMiniBins, readSettings } from './utils/board';
import { syncMapping } from './common/mini24_bins_mapping';
import BOX_TYPE from './constant/boxTypes';

function App() {
  const dispatch = useDispatch();
  let socket;

  const getBoxesData = async () => {
    let boxId;
    let response;
    if (window.electron) {
      const fileData = JSON.parse(readSettings());
      boxId = fileData ? fileData.boxId : '';
    } else {
      boxId = localStorage.getItem('boxId');
    }
    if (![undefined, 'undefined', null, null, ''].includes(boxId)) {
      response = await API.get(`apis/i-ecom/orders/${boxId}/list`).then((res) => {
        dispatch(updateData(res.data));
        localStorage.setItem('typeId', res.data.type_id);
        return res.data;
      }).catch((err) => console.log(err));
    }
    return response;
  };

  const initSocket = async () => {
    const _token = localStorage.getItem('authTokens');
    const decodedToken = _token && atob(_token);
    if (decodedToken && decodedToken.length > 21) {
      socket = io(process.env.REACT_APP_VIDEO_SOCKET_BASEURL, {
        transports: ['websocket'],
        auth: {
          token: `Bearer ${decodedToken}`,
        },
        secure: true,
      });
      console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@     Socket init from App.js      @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@');
      if (socket) {
        socket.on('connect', () => {
          console.log('Socket connection established from PORTAL(ReConnect)');
        });

        socket.emit('ConfigureBoxId', {
          box_id: localStorage.getItem('boxId'),
        });

        socket.on('KioskOpenBin', async (payload) => {
          const boxDetails = await getBoxesData();
          const boxType = ![undefined, 'undefined', null, 'null', ''].includes(boxDetails) ? boxDetails.type_id : localStorage.getItem('typeId');
          if (boxType === BOX_TYPE.M_24) {
            console.log('$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$', payload);
            const bin_id = syncMapping(payload.bin);
            openMiniBins(bin_id);
          }
        });

        socket.on('connect_error', (error) => {
          console.log('Connect Error: ', error);
          if (
            error.message === 'TokenExpiredError'
            || error.message === 'JsonWebTokenError'
            || error.message === 'NoTokenFound'
          ) {
            console.log('Token issue!');
          }
        });

        socket.on('connect_failed', (error) => {
          console.log('Connect Failed: ', error);
        });

        socket.on('error', (error) => {
          console.log('Error: ', error);
        });

        socket.on('disconnect', (reason) => {
          console.log(
            `Socket Disconnected From Portal and Reason is: ${reason}`,
          );
        });
      }
    } else {
      console.log('***initSocket*** Not a valid token for initializing socket connection!');
    }
  };

  useEffect(() => {
    getBoxesData();
    initSocket();
    dispatch({ type: sagaActions.FETCH_BOX });
  }, []);

  return (
    <Suspense
      fallback={(
        <div className="flex justify-center align-middle h-screen">
          <Loader />
        </div>
      )}
    >
      <RouterProvider router={router}>
        {/* Components will be rendered here */}
      </RouterProvider>
    </Suspense>
  );
}

export default App;
